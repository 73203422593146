* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
}

.App {
  height: 100vh;
  width: 100vw;
}

#explorer {
  background: #22252a;
  width: 100vw;
  height: 48px;
  display: flex;
  align-items: center;
}
#explorer .explorer-title {
  display: flex;
  align-items: center;
  color: #aab2bf;
  font-weight: 500;
  font-size: 18px;
  height: 100%;
  width: 20%;
  max-width: 375px;
  padding: 0 32px;
}
#explorer .active-page {
  display: flex;
  align-items: center;
  background-color: #323842;
  height: 100%;
  padding: 0 24px;
  border-bottom: 1px solid #eee;
}
#explorer .active-page svg {
  height: 25px;
  width: 25px;
  margin-right: 12px;
}
#explorer .active-page p {
  color: #fff;
}

#content-container {
  width: 100vw;
  height: calc(100% - 48px);
  display: flex;
}

nav {
  background-color: #22252b;
  height: 100%;
  width: 20%;
  max-width: 375px;
  display: flex;
  flex-direction: column;
}
nav .nav-projectname {
  padding: 4px;
  padding-left: 32px;
  font-weight: 700;
  color: #abb2bf;
  background-color: #282c34;
}
nav .nav-listitem {
  display: flex;
  align-items: center;
  max-height: 32px;
  width: 100%;
  padding: 8px 8px 8px 32px;
  text-decoration: none;
}
nav .nav-listitem svg {
  margin-right: 8px;
}
nav .nav-listitem p {
  color: #aab2bf;
}
nav .nav-listitem:hover {
  background-color: #323742;
  cursor: pointer;
}
nav .nav-listitem-child1 {
  padding-left: 48px;
}
nav .nav-listitem-child2 {
  padding-left: 64px;
}

#body {
  background-color: #282c34;
  width: 80%;
  flex-grow: 1;
  padding: 48px;
  overflow-y: scroll;
}
#body section {
  color: rgb(240, 240, 240);
}
#body section h1 {
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 3px;
  font-weight: 700;
}
#body section h2 {
  font-size: 32px;
  line-height: 64px;
  letter-spacing: 3px;
  font-weight: 700;
}
#body section p {
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: 500;
}

#home .animatedh2 {
  display: flex;
  align-items: center;
}
#home .changing-word {
  color: #e06b76;
}
#home .important-links {
  padding: 32px 0;
}
#home .important-links .important-links-links-container {
  display: flex;
}
#home .important-links .important-links-links-container a {
  font-size: 24px;
  line-height: 32px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 12px;
  padding: 8px;
  border-radius: 8px;
  min-height: 72px;
  min-width: 72px;
}
#home .important-links .important-links-links-container a svg {
  transition: transform 0.2s ease;
}
#home .important-links .important-links-links-container a svg path {
  fill: #6f7179;
}
#home .important-links .important-links-links-container a svg:hover {
  color: #e4c07b;
}
#home .important-links .important-links-links-container a svg:hover svg {
  transform: scale(1.2);
}
#home .important-links .important-links-links-container a svg:hover svg path {
  fill: #d6dae0;
}

#about .about-img-and-content {
  display: flex;
  margin-top: 24px;
}
#about .about-img-and-content img {
  height: 580px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 6px;
}
#about .about-img-and-content p {
  flex-grow: 1;
  max-width: 650px;
  font-size: 24px;
  line-height: 24px;
  padding: 0 32px;
}

#skills .all-skills-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#skills .all-skills-container .skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px;
  width: 250px;
  border-radius: 8px;
  padding: 24px 12px;
  background-color: #323842;
  box-shadow: 0 3px 4px 4px #22262d;
}
#skills .all-skills-container .skill svg {
  height: 128px;
}
#skills .all-skills-container .skill p {
  padding: 18px;
}

#projects .project {
  display: flex;
  flex-direction: column;
}
#projects .project p {
  font-size: 18px;
  max-width: 1000px;
}
#projects .project .project-links button {
  margin: 18px 18px 18px 0;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid black;
  background-color: #eee;
}
#projects .project .project-links button:hover {
  background-color: #ccc;
  cursor: pointer;
}
#projects .project .techStack {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 16px 0;
}
#projects .project .techStack svg {
  height: 48px;
  width: 48px;
  margin: 0 8px;
}
#projects .project img {
  width: 1000px;
  border-radius: 6px;
  margin: 12px 0;
}/*# sourceMappingURL=App.css.map */